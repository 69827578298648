<template>
  <div class="cart-empty">
    <div class="message">
      {{ $t('cart.cartEmptyGpe') }}
    </div>
    <div>
      <a
        href="/packages"
        class="btn btn-secondary"
      >{{ $t('cart.continueShopping') }}</a>
    </div>
  </div>
</template>
