<template>
  <div
    v-if="enabled"
    id="navCartForm"
    :class="[openClass, 'navbar-cart-form']"
  >
    <span
      class="empty-section"
      @click="hideCart"
    />

    <div class="navbar-vue-cart">
      <div class="header-section">
        <h3 class="header-title">
          {{ $t('navCart.yourItems') }}
        </h3>

        <div class="currency-and-close">
          <button
            v-if="order.disabled_cart"
            class="btn clear-cart"
            @click="clearCart"
          >
            Clear Cart
          </button>

          <span class="currency">
            <CurrencyDropdown :currency="currency" />
          </span>
          <button
            class="btn"
            @click="hideCart"
          >
            {{ $t('navCart.close') }}
          </button>
        </div>
      </div>

      <FullGpeCart
        ref="cart"
      />


      <div class="checkout-buttons">
        <a
          class="btn proceed-to-checkout"
          :class=" disabledContinueToCheckout ? 'disabled' : '' "
          :href="checkoutLink"
        >
          {{ $t('navCart.continueGpe') }}
        </a>
        <button
          class="btn continue-shopping"
          @click="hideCart"
        >
          {{ $t('navCart.keepShopping') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import Cart from '../../shared/cart/cart'
import FullGpeCart from '../cart/cart'
import CurrencyDropdown from '../../default/currency_convert/currency-dropdown'
import CurrencyApi from '../../default/currency_convert/currency-api'

import { mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    FullGpeCart,
    CurrencyDropdown
  },

  data() {
    return {
      amount: 0,
      checkoutLink: '/cart/checkout',
      currency: 'USD',
      enabled: true,
      navLinkTag: null,
      orderId: null
    }
  },

  computed: {
    ...mapGetters('cart', ['openClass']),
    order(){
      return this.$store.state.order.order
    },
    disabledContinueToCheckout(){
      return this.order.items && (this.order.items.length < 1  || this.invalidItems > 0)
    },
    orderCurrency(){
      return this.$store.state.order.order.currency
    },
    invalidItems() {
      if (this.order && this.order.items) {
        return this.order.items.filter(item => {
          return (
            item.hotel_id == null && item.package_hotel_required ||
            item.price_change
          )
        })
      } else {
        return []
      }
    }
  },

  watch: {
    orderCurrency(){
      this.currency = this.orderCurrency
    }
  },

  created() {
    if (this.chargeablecurrencies == undefined) {
      // Use all currencies for Event Pages
      this.fetchCurrencies()
    } else {
      // Only use chargeableCurrencies for cart/order vues
      this.getCurrentCurrency()
      this.updateCurrencies(['USD'].concat(this.chargeablecurrencies))
    }
    this.navLinkTag = document.getElementById('navCartLink')
    if (this.navLinkTag == null) { return }
    this.subNavLinkTag = document.getElementById('subNavCartLink')
    this.checkoutLink = '/cart/summary'

    if (document.getElementById('cartNavCheck')) {
      this.navLinkTag.href = this.checkoutLink
      this.enabled = false
    } else {
      this.navLinkTag.addEventListener('click', this.displayCart)
      if (this.subNavLinkTag) { this.subNavLinkTag.addEventListener('click', this.displayCart) }
    }
  },

  methods: {
    ...mapMutations('cart', ['displayCart', 'hideCart']),
    ...mapMutations('order', ['updateCurrencies', 'updateCurrentCurrency', 'updateExchangeRate']),

    fetchCurrencies() {
      CurrencyApi.availableCurrencies().then(response => {
        this.updateCurrencies(response.currencies)
        this.updateCurrentCurrency(response.current_currency)
        this.updateExchangeRate(response.rate)
        // this.currencies = currencies.currencies
        // this.current = currencies.current_currency
        this.$emit('currency-emit', this.error_message, this.current, Number(response.rate))
      })
    },

    getCurrentCurrency() {
      CurrencyApi.getCurrentCurrency().then(response => {
        if (response.currency) {
          this.updateCurrentCurrency(response.currency)
          this.updateExchangeRate(response.rate)
          this.$emit('currency-emit', this.error_message, this.current, Number(response.rate))
        }
      })
    },

    clearCart() {
      let cart = this.$refs.cart
      cart.clearCart()
      // must trigger action in nav cart, but set order in cart.vue
    },

    cartClose() {
      this.display = false
      document.querySelector('body').classList.remove('nav-cart-displaying')
    },
  }
}
</script>
